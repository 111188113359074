<style scoped>
.modalItem {
  height: 80vh;
  overflow: scroll;
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  text-align: center;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
}
.chooseDeptFormItem {
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
  display: flex;
  justify-content: space-between;
  min-height: 41px;
  line-height: 41px;
}
.chooseDeptFormItem .chooseDeptFormItemTitle {
  font-weight: bold;
  color: #2d4761;
  flex: 1;
}
.chooseDeptFormItem .detail {
  flex: 1;
}
</style>
<template>
  <div v-if="showModal">
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <Modal
      v-model="showChooseDept"
      fullscreen
      @onCancel="cancel"
      @onOk="chooseDept"
      :closable="false"
    >
      <p slot="header" style="text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>选择单位</span>
      </p>

      <div v-show="isShowTime">
        <div class="chooseDeptFormItem">
          <div class="chooseDeptFormItemTitle">开始日期</div>
          <div class="detail">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              :clearable="false"
              :option="option"
              :value="pickerValue"
              @on-change="setStartDateValue"
              placeholder="请选择时间"
              width="200px"
            ></DatePicker>
          </div>
        </div>

        <div class="chooseDeptFormItem">
          <div class="chooseDeptFormItemTitle">结束日期</div>
          <div class="detail">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              :clearable="false"
              :option="option"
              :value="pickerEndValue"
              @on-change="setEndDateValue"
              placeholder="请选择时间"
              width="200px"
            ></DatePicker>
          </div>
        </div>
      </div>

      <div v-if="showSuper && isShowDept" class="modalItem" style="">
        <div class="detail">
          <CheckboxGroup
            v-model="deptIdArr"
            @on-change="choosedeptIdArr"
            style="text-align: left; line-height: 30px"
          >
            <Checkbox
              v-for="item in deptList"
              :key="item.deptId"
              :label="item.deptId"
            >
              <span>{{ item.deptName }}</span>
            </Checkbox>
          </CheckboxGroup>
        </div>
      </div>

      <div slot="footer" style="text-align: center; position: relative">
        <!-- :disabled="deptIdArr.length == 0" -->
        <Button type="info" @click="chooseDept">确定</Button>
        <Button
          :disabled="disableChildStats || deptIdArr.length == 0"
          type="info"
          @click="getChildDept"
          >子部门</Button
        >
        <Button @click="resetDept">重置</Button>
        <Button type="default" @click="cancel">取消</Button>

        <!-- <div @click="showtext" style="position: absolute; right: 5px">
          <svg
            t="1672370678750"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2734"
            width="20"
            height="20"
          >
            <path
              d="M512 128C299.92 128 128 299.92 128 512s171.92 384 384 384 384-171.92 384-384S724.08 128 512 128z m0 704c-176.448 0-320-143.552-320-320s143.552-320 320-320 320 143.552 320 320-143.552 320-320 320z"
              fill="#ccc"
              p-id="2735"
            ></path>
            <path
              d="M512 256c-88.224 0-160 71.776-160 160a32 32 0 1 0 64 0c0-52.944 43.056-96 96-96s96 43.056 96 96-43.056 96-96 96a32 32 0 0 0-32 32v64a32 32 0 1 0 64 0v-35.232c72.944-14.864 128-79.52 128-156.768 0-88.224-71.776-160-160-160z"
              fill="#ccc"
              p-id="2736"
            ></path>
            <path
              d="M512 720m-48 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z"
              fill="#ccc"
              p-id="2737"
            ></path>
          </svg>
        </div> -->
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from "lodash";

import { statsPost, getUserPm } from "../api/httpApi";
import diaLogliu from "./dialogLiu.vue";
import moment from "moment";

let curDate = new Date();

export default {
  name: "deptChooseCom",
  display: "选择单位",
  data () {
    return {
      level: 0,
      disableChildStats: false,
      showSuper: false, // 超管显示部门
      deptList: [], // 超过可选部门

      deptIdArr: [], // 选择部门Id

      showChooseDept: false,
      // 提示框
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
      // 选择时间
      pickerValue: new Date(curDate.getTime() - 7 * 24 * 60 * 60 * 1000),
      pickerEndValue: curDate,
      startDate: "",
      endDate: "",

      option: {},
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
    // 是否需要选择时间
    isShowTime: {
      type: Boolean,
      default: false,
    },
    isShowDept: {
      type: Boolean,
      default: false,
    },
    defaultStartDate: {
      type: String,
      default: "",
    },
    defaultEndDate: {
      type: String,
      default: "",
    },
    // 是否显示车间
    showChejian: {
      type: Boolean,
      default: true,
    },
  },
  watch: {},
  computed: {},
  components: { diaLogliu },
  methods: {
    showtext () {
      console.log("------", this.showTime);
      this.showTime = !this.showTime;
    },
    resetDept () {
      // 验证查询权限
      const userPm = getUserPm();
      if (userPm && userPm.level && userPm.statsPmStatus) {
        this.level = userPm.level;
        this.disableChildStats = false;
        this.showSuper = true;
        this.deptIdArr = [];
        this.getDept();
      }
    },
    choosedeptIdArr (item) { },

    async getDept () {
      try {
        const parentDept = JSON.parse(localStorage.getItem("pmDeptIdArr"));
        const result = await statsPost("/dept/getDept", {
          level: this.level,
          parentDept,
        });
        this.deptList = result.ret;
        // 自定义排序函数
        const sortByFirstChar = (a, b) => {
          // 提取首汉字并进行比较
          return a.deptName.charAt(0).localeCompare(b.deptName.charAt(0), 'zh-CN', { sensitivity: 'base' });
        };

        // 使用sort方法进行排序
        this.deptList.sort(sortByFirstChar);
      } catch (e) { }
    },
    async getChildDept () {
      // 取当前部门列表 将 level赋值

      try {
        const result = await statsPost("/dept/getDept", {
          level: this.level + 1,
          parentDept: this.deptIdArr,
        });

        if (result.ret && result.ret.length == 0) {
          this.dailogshow = true;
          this.dailogoptions.content = "无子部门";
          this.dailogoptions.textColor = "white";
          console.log("--------", this.level);
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
          return;
        }
        this.deptIdArr = []; // 查询完子级部门，将上级部门选中删除
        this.deptList = result.ret;
        this.level = this.deptList[0].level || this.level;
        // 自定义排序函数
        const sortByFirstChar = (a, b) => {
          // 提取首汉字并进行比较
          return a.deptName.charAt(0).localeCompare(b.deptName.charAt(0), 'zh-CN', { sensitivity: 'base' });
        };

        // 使用sort方法进行排序
        this.deptList.sort(sortByFirstChar);
      } catch (e) {
        console.log("---查询子部门异常", e.message);
      }
    },

    async chooseDept () {
      // 未选择部门情况下，默认取当前可见部门列表，不判断是否选中
      // if (this.showSuper && this.isShowDept && this.deptIdArr.length == 0) {
      //   this.dailogshow = true;
      //   this.dailogoptions.content = "请选择单位";
      //   this.dailogoptions.textColor = "white";
      //   setTimeout(() => {
      //     this.dailogshow = false;
      //   }, 2000);
      //   return;
      // }
      let _hasChild = true;
      // 当前已经选中部门
      if (this.showSuper && this.deptIdArr.length > 0) {
        const result = await statsPost("/dept/getDept", {
          level: this.level + 1,
          parentDept: this.deptIdArr,
        });
        if (result.ret && result.ret.length == 0) {
          // 无子部门
          _hasChild = false;
        }
      }
      console.log("--------", _hasChild);

      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        this.$Message.info("日期选择不合法");
        return;
      }

      const _curChooseDept = _.isEmpty(this.deptIdArr)
        ? _.map(this.deptList, "deptId")
        : this.deptIdArr;
      let emitData = {
        // 存在子部门，查询子集部门；不存子部门，查询当前选中部门
        level: _hasChild ? this.level + 1 : this.level,
        deptIdArr: _curChooseDept,
      };
      if (this.isShowTime) {
        emitData["startDate"] = this.startDate;
        emitData["endDate"] = this.endDate;
      }
      // console.log("-------", emitData, _hasChild ? this.level + 1 : this.level);
      this.$emit("chooseDept", emitData);
    },
    cancel () {
      this.resetDept();
      this.$emit("cancel");
    },

    /****************************** 设置时间 */
    setEndDateValue (date) {
      this.endDate = date;
      this.pickerEndValue = new Date(date);
      // console.log("enddate-----", date, this.startDate, this.endDate);
      if (this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }
    },
    setStartDateValue (date) {
      this.startDate = date;
      this.pickerValue = new Date(date);
      if (this.endDate && this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }
    },
  },

  async created () {
    // 若需日期
    if (this.isShowTime) {
      // 初始化开始日期
      if (this.defaultStartDate) {
        this.startDate = moment(this.defaultStartDate).format("YYYY-MM-DD");
        this.pickerValue = new Date(this.startDate);
      } else {
        this.startDate = moment(new Date(this.pickerValue)).format(
          "YYYY-MM-DD"
        );
      }

      // 初始化结束日期
      if (this.defaultEndDate) {
        this.endDate = moment(this.defaultEndDate).format("YYYY-MM-DD");
        this.pickerEndValue = new Date(this.endDate);
      } else {
        this.endDate = moment(new Date(this.pickerEndValue)).format(
          "YYYY-MM-DD"
        );
      }
    }
    // 验证查询权限
    const userPm = getUserPm();
    console.log("userPm--------", userPm);
    if (userPm && userPm.statsPmStatus) {
      this.level = userPm.level;
      this.disableChildStats = false;
      this.showSuper = true;
      this.getDept();
    }
  },
  watch: {
    showModal: function (val) {
      this.showChooseDept = this.showModal;
    },
  },
  beforeDestroy () {
    this.showChooseDept = false;
  },
};
</script>
